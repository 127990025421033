import * as React from 'react';
import * as styles from './terms.module.scss';

import Callout from '../components/v2/callout';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';

const JobsPage = () => {
  return (
    <>
      <SEO
        title="Jobs"
        description="Explore exciting career opportunities at Interact, the leading quiz software company. Join our innovative team and shape the future of interactive content."
      />
      <Header btnTheme="primary" />
      <main>
        <Section theme="default" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl">Work at Interact</h1>
              <p className="text-body color-muted-foreground">Open Positions: N/A</p>
            </div>
          </div>
        </Section>
        <Section theme="grey" className={styles.terms}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <p className="heading-sm color-muted-foreground">
                No current opportunities. Stay tuned for future openings.
              </p>
            </div>
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
    </>
  );
};

export default JobsPage;
